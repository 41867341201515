import React from 'react'
import { Spinner } from './spinner'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

export enum ButtonStyle {
  PRIMARY,
  RED,
  GREY,
}
export type ButtonAdminProps = {
  onClick?: (e) => void
  disabled?: boolean
  loading?: boolean
  className?: string
  text: string
  style?: ButtonStyle
  icon?: React.ReactNode
}

const ButtonAdminComponent: React.FC<ButtonAdminProps> = ({
  onClick,
  disabled = false,
  loading = false,
  className,
  text,
  style = ButtonStyle.PRIMARY,
  icon,
}) => {
  return (
    <button
      className={twMerge(
        'flex h-10 items-center space-x-2 rounded-md bg-admin-blue px-4 leading-none text-white',
        style == ButtonStyle.PRIMARY && 'bg-admin-blue',
        style == ButtonStyle.RED && 'bg-red-600',
        style == ButtonStyle.GREY &&
          'bg-admin-grey-300 text-black  border border-[#00000020]',
        disabled && 'opacity-50',
        className
      )}
      onClick={onClick}
      disabled={loading || disabled}>
      {!loading && (
        <>
          {icon}
          <span>{text}</span>
        </>
      )}
      {loading && (
        <div className="flex items-center justify-center h-full w-full">
          <Spinner color="white" />
        </div>
      )}
    </button>
  )
}

export default ButtonAdminComponent
