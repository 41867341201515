'use client'
import { motion } from 'framer-motion'
import { MouseEventHandler, ReactNode, forwardRef } from 'react'

export type ButtonProps = {
  children: ReactNode
  onClick?: MouseEventHandler<HTMLDivElement>
  className?: string
}
export const Button = forwardRef(function ButtonImpl(
  { children, onClick, className }: ButtonProps,
  ref
) {
  return (
    <motion.div
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      ref={ref as any}
      className={'buttons' + (className ? ' ' + className : '')}>
      {children}
    </motion.div>
  )
})
