import { Editor, Element, Element as SlateElement, Transforms } from 'slate'

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

export const LIST_TYPES = ['numbered-list', 'bulleted-list']
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const toggleMark = (editor, format, value: any = true) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, value)
  }
}

export const isBlockActive = (editor, format): [boolean, any] => {
  const blockType = TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'

  const { selection } = editor
  if (!selection) return [false, null]

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  const isActive = !!match

  return [isActive, isActive && match[0]]
}

export const isMarkActive = (editor, format): boolean => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}
