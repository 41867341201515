import * as React from 'react'

export function RenderLeaf({ attributes, children, leaf, ...props }) {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.semibold) {
    children = <p className={'font-semibold'}>{children}</p>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}
