'use client'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useSlate } from 'slate-react'
import { addNode, removeElement } from '../services/node'
import { LinkDialog, LinkForm } from './dialog'
import { isBlockActive } from '../funcitons'

export function LinkMark({
  dialogTitle,
  className,
  children,
  format,
}: {
  dialogTitle: string
  format: string
  className?: string
  children: React.ReactNode
}) {
  const [open, setOpen] = useState(false)
  const editor = useSlate()
  const [isActive, node] = isBlockActive(editor, format)

  const onConfirmValue = (value: LinkForm) => {
    addNode(editor, { type: format, text: '', url: value.link })
    setOpen(false)
  }

  const onRemoveButton = () => {
    removeElement(editor, format)
    setOpen(false)
  }

  return (
    <LinkDialog
      title={dialogTitle}
      open={open}
      value={isActive && node ? node?.url : undefined}
      onOpenChange={setOpen}
      onConfirmValue={onConfirmValue}
      onRemoveButton={onRemoveButton}>
      <motion.button
        whileTap={{ scale: 0.95 }}
        className={`text-center w-[25px] h-[25px] text-gray-600 hover:bg-gray-300 cursor-pointer rounded-[5px] flex justify-center items-center ${isActive ? ' bg-gray-300' : ''}${className ? ` ${className}` : ''}`}>
        {children}
      </motion.button>
    </LinkDialog>
  )
}
