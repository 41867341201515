'use client'
import { keyframes, styled } from '../config'

const shine = keyframes({
  to: { backgroundPositionX: '-200%' },
})

export const Placeholder = styled('div', {
  position: 'relative',
  height: '100%',
  width: '100%',
  borderRadius: '4px',
  animation: `${shine} 1.5s linear infinite`,

  variants: {
    color: {
      lightGrey: {
        background:
          'linear-gradient(110deg, #fafafa 8%, #f5f5f5 18%, #fafafa 33%)',
        backgroundSize: '200% 100%',
      },
      darkGrey: {
        background:
          'linear-gradient(110deg, #ECECEC 8%, #E5E5E5 18%, #ECECEC 33%)',
        backgroundSize: '200% 100%',
      },
    },
  },
  defaultVariants: {
    color: 'darkGrey',
  },
})
