import { useFocused, useSelected } from 'slate-react'

export const CustomVideo = ({ attributes, children, element, style }) => {
  const isBunny = element.url?.includes('iframe.mediadelivery.net')
  const isYoutube = !isBunny && element.url?.includes('youtube')
  const selected = useSelected()
  const focused = useFocused()

  return (
    <div
      {...attributes}
      style={{
        display: 'block',
        maxWidth: '100%',
        maxHeight: '20em',
        boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
        ...style,
      }}
      className={'video-custom'}>
      {(isBunny || isYoutube) && (
        <iframe
          src={element.url}
          loading="lazy"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen={true}
        />
      )}
      {children}
    </div>
  )
}
