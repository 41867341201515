import { ReactNode } from 'react'
import '../comment-view.css'

export function CommentContainer({
  children,
  className,
}: {
  className?: string
  children: ReactNode
}) {
  return (
    <section className={'comment-view' + (className ? ' ' + className : '')}>
      {children}
    </section>
  )
}
