import { Button, ButtonProps } from './button'

export function EditButton({ children, className, onClick }: ButtonProps) {
  return (
    <Button
      onClick={onClick}
      className={`flex justify-center items-center flex-row gap-2 text-blue-secondary cursor-pointer ${className}`}>
      <EditIcon />
      {children}
    </Button>
  )
}

export const EditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    {...props}>
    <path
      fill="#000"
      d="M0 7.917V10h2.083l6.144-6.144-2.083-2.083L0 7.917Zm9.838-5.671a.553.553 0 0 0 0-.784l-1.3-1.3a.553.553 0 0 0-.784 0L6.738 1.18 8.82 3.262l1.017-1.016Z"
      opacity={0.6}
    />
  </svg>
)
