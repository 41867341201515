'use client'
import { Label } from '@radix-ui/react-label'
import { styled } from '../config'
import { Flex } from './flex'

export const Input = styled('input', {
  all: 'unset',
  background: '$white',
  border: '1px solid #ECECEC',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '$1',
  paddingInline: '8px',
  '&:focus': {
    background: '$adminGrey50',
  },
})

export function Field({
  label,
  register,
  ...rest
}: {
  label?: string
  register: () => any
  name: string
} & React.ComponentProps<typeof Input>) {
  return (
    <Flex css={{ flexDirection: 'column' }}>
      {label && (
        <Label className="text-[#808080] text-sm mb-2" htmlFor={rest.name}>
          {label}
        </Label>
      )}
      <Input id={rest.name} {...rest} {...register()} />
    </Flex>
  )
}
