'use client'

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { styled } from '../config'

export const StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  backgroundColor: 'transparent',
  width: 16,
  minWidth: 16,
  minHeight: 16,
  height: 16,
  borderRadius: '100%',

  variants: {
    variant: {
      exam: {
        border: '$grey100 1px solid',
        '&[aria-checked="true"]': {
          borderColor: 'rgba(83, 104, 229, 1)',
        },
      },
      selection: {
        border: '$grey100 1px solid',
        '&[aria-checked="true"]': {
          borderColor: '$blue100',
        },
      },
      grey: {
        border: '$grey100 1px solid',
        '&[aria-checked="true"]': {
          borderColor: '' +
            '$adminGrey500',
        },
      },
      blue: {
        border: '$grey100 2px solid',
        '&[aria-checked="true"]': {
          borderColor: '#0866F2',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'selection',
  },
})

export const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    width: 10,
    height: 10,
    borderRadius: '50%',
  },

  variants: {
    variant: {
      exam: {
        '&::after': {
          backgroundColor: 'rgba(83, 104, 229, 1)',
        },
      },
      selection: {
        '&::after': {
          backgroundColor: '$blue100',
        },
      },
      grey: {
        '&::after': {
          backgroundColor: '$adminGrey500',
        },
      },
      blue: {
        '&::after': {
          backgroundColor: '#0866F2',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'selection',
  },
})

export const RadioGroupRoot = styled(RadioGroupPrimitive.Root)
export const RadioGroupRadio = StyledRadio
export const RadioGroupIndicator = StyledIndicator
