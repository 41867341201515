'use client'
import * as RadixCheckbox from '@radix-ui/react-checkbox'
import { styled } from '../config'
import { Check } from '../icons'
import { Flex } from './flex'

export function Checkbox({
  label,
  name,
  onChange,
  defaultChecked,
}: {
  label?: string
  name: string
  onChange: (val: boolean) => void
  defaultChecked?: boolean
}) {
  return (
    <Flex as="label" css={{ spacingX: '0.5rem' }}>
      <Root
        id={name}
        defaultChecked={defaultChecked}
        onCheckedChange={onChange}>
        <RadixCheckbox.Indicator>
          <Check css={{ square: 12 }} />
        </RadixCheckbox.Indicator>
      </Root>
      {label && <span>{label}</span>}
    </Flex>
  )
}

const Root = styled(RadixCheckbox.Root, {
  background: 'white',
  boxShadow: `0 0 0 1.5px #737980`,
  '&[data-state="checked"]': {
    background: 'rgba(8, 102, 242, 1)',
    boxShadow: `0 0 0 1.5px rgba(8, 102, 242, 1)`,
  },
  borderRadius: '4px',
  width: 14,
  height: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
