'use client'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { styled } from '../config'

const StyledTabs = styled(TabsPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
})

const StyledList = styled(TabsPrimitive.List, {
  flexShrink: 0,
  display: 'flex',
})

const StyledTrigger = styled(TabsPrimitive.Trigger, {
  all: 'unset',
})

const StyledContent = styled(TabsPrimitive.Content, {
  padding: '26px 32px',
  '@bp1': {
    padding: '24px 23px',
  },
})

// Exports
export const Tabs = StyledTabs
export const TabsList = StyledList
export const TabsTrigger = StyledTrigger
export const TabsContent = StyledContent
