'use client'
import { styled } from '../../config'

function Svg(props) {
  return (
    <svg
      id="Capa_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 117.83 40.58"
      {...props}>
      <defs>
        <style>
          {'.cls-1{fill:#6d40c8}.cls-2{fill:#f88333}.cls-3{fill:#1d1d1b}'}
        </style>
      </defs>
      <g id="Capa_1-2">
        <circle className="cls-2" cx="39.29" cy="10.55" r="3.07" />
        <circle className="cls-1" cx="3.07" cy="29.53" r="3.07" />
        <path
          className="cls-3"
          d="m15.19,15.17c-1.07.03-2.17.05-3.24.05h-2.41v16.81h4.82v-12.02c.32,0,.63-.01.95-.02,5.03-.12,9.38-.23,11.84,2.17,1.82,1.78,2.71,5.01,2.71,9.87h4.82c0-6.23-1.36-10.59-4.17-13.32-3.91-3.82-9.71-3.68-15.32-3.54Z"
        />
        <rect
          className="cls-3"
          x="36.88"
          y="15.33"
          width="4.82"
          height="16.7"
        />
        <path
          className="cls-3"
          d="m65.87,20c-6.67-5.96-17.61-4.81-18.07-4.76l-2.14.24v16.55h4.82v-12.1c3.38,0,8.83.67,12.17,3.66,2.24,2,3.32,4.76,3.32,8.44h4.82c0-5.06-1.66-9.11-4.93-12.04Z"
        />
        <path
          className="cls-3"
          d="m117.83,22.37h-4.96v-7.15h-2.41c-1.08,0-2.18-.03-3.24-.05-5.61-.14-11.41-.28-15.32,3.54-2.8,2.74-4.17,7.09-4.17,13.32h4.82c0-1.85.13-3.45.39-4.84h15.11v4.84h4.82v-4.84h4.96v-4.82Zm-22.75,0c.06-.07.12-.14.18-.21,2.46-2.4,6.8-2.29,11.84-2.17.31,0,.63.02.95.02v2.35h-12.97Z"
        />
        <path
          className="cls-3"
          d="m84.52,15.44h-14.4v4.82h12.09c0,3.04-.48,8.64-3.66,12.19-2,2.23-4.76,3.31-8.43,3.31v4.82c5.06,0,9.11-1.66,12.04-4.93,5.96-6.67,4.81-17.61,4.76-18.07-.14-1.22-1.17-2.14-2.4-2.14Z"
        />
        <path
          className="cls-3"
          d="m51.56,7.91v.55c-.13.02-.28.04-.44.04-.33,0-.57-.08-.72-.24s-.23-.41-.23-.75v-.08c-.41.79-1.15,1.19-2.22,1.19-.66,0-1.17-.16-1.53-.47-.36-.31-.54-.74-.54-1.29,0-1.06.73-1.68,2.18-1.85l.95-.11c.42-.06.71-.16.88-.31.17-.15.25-.38.25-.69,0-.74-.53-1.12-1.58-1.12-.61,0-1.05.11-1.32.32-.27.21-.42.57-.44,1.08h-.65c.04-1.32.85-1.98,2.42-1.98.74,0,1.3.15,1.67.44.37.3.56.71.56,1.25v3.58c0,.29.15.44.44.44h.32Zm-2.03-.4c.41-.34.62-.85.62-1.53v-.93c-.2.2-.54.32-1.02.38l-.95.12c-.56.07-.97.21-1.22.41-.25.2-.37.49-.37.87s.12.66.36.88c.24.21.59.32,1.04.32.61,0,1.12-.17,1.54-.52Z"
        />
        <path
          className="cls-3"
          d="m56.92,0h.68v8.45h-.64v-1.2c-.47.89-1.21,1.34-2.23,1.34-.85,0-1.52-.29-2.02-.88-.51-.58-.76-1.35-.76-2.33s.25-1.75.76-2.33c.5-.59,1.17-.88,2.02-.88,1,0,1.73.42,2.2,1.27V0Zm-2.12,7.99c.65,0,1.17-.23,1.57-.7.39-.46.58-1.09.58-1.9s-.19-1.44-.58-1.9c-.4-.47-.92-.7-1.57-.7s-1.18.24-1.57.71c-.39.48-.58,1.12-.58,1.92s.19,1.42.58,1.88c.39.46.91.69,1.57.69Z"
        />
        <path
          className="cls-3"
          d="m66.98,2.77c.36.39.54.94.54,1.63v4.05h-.67v-3.98c0-1.12-.5-1.69-1.51-1.69-.51,0-.94.2-1.26.6-.33.4-.5.97-.5,1.7v3.36h-.68v-3.98c0-1.12-.5-1.69-1.51-1.69-.51,0-.92.2-1.25.6-.33.4-.5.97-.5,1.7v3.36h-.68V2.33h.64v1.13c.38-.85,1.01-1.27,1.9-1.27,1,0,1.65.45,1.95,1.34.42-.89,1.08-1.34,1.98-1.34.67,0,1.19.2,1.55.59Z"
        />
        <path
          className="cls-3"
          d="m68.87,1.4V.28h.71v1.12h-.71Zm.02,7.05V2.33h.68v6.13h-.68Z"
        />
        <path
          className="cls-3"
          d="m75.22,2.77c.38.39.57.94.57,1.65v4.04h-.68v-3.98c0-1.12-.54-1.69-1.63-1.69-.5,0-.93.19-1.31.57-.36.38-.55.93-.57,1.66v3.43h-.68V2.33h.64v1.14c.2-.4.47-.72.83-.94.36-.23.76-.34,1.21-.34.69,0,1.22.19,1.6.58Z"
        />
        <path
          className="cls-3"
          d="m77.13,1.4V.28h.71v1.12h-.71Zm.02,7.05V2.33h.68v6.13h-.68Z"
        />
        <path
          className="cls-3"
          d="m79.52,8.07c-.44-.35-.67-.85-.71-1.52h.69c.08.97.7,1.46,1.85,1.46s1.71-.39,1.71-1.16c0-.32-.11-.56-.34-.74-.23-.17-.62-.31-1.16-.42l-.63-.11c-.66-.12-1.14-.32-1.45-.6-.31-.28-.47-.66-.47-1.13,0-.52.19-.93.58-1.23.39-.3.93-.44,1.61-.44,1.56,0,2.37.63,2.42,1.9h-.69c-.03-.45-.19-.78-.46-1-.28-.21-.7-.32-1.27-.32-.48,0-.86.09-1.12.29-.26.19-.4.46-.4.79,0,.32.11.56.34.74s.6.31,1.11.4l.62.11c.7.13,1.21.33,1.53.6.31.27.47.65.47,1.12,0,.58-.21,1.02-.64,1.32s-1.01.46-1.75.46c-.78,0-1.39-.17-1.83-.52Z"
        />
        <path
          className="cls-3"
          d="m87.1,2.92h-1.42v4.25c0,.48.29.72.87.72.3,0,.49,0,.56-.01v.57c-.23.02-.44.04-.64.04-.97,0-1.45-.42-1.45-1.26V2.92h-1.04v-.59h1.04V.59h.66v1.73h1.42v.59Z"
        />
        <path
          className="cls-3"
          d="m91.13,2.25v.64h-.29c-.67,0-1.2.19-1.57.57s-.56.9-.56,1.56v3.43h-.68V2.33h.64v1.28c.41-.91,1.12-1.37,2.11-1.37.17,0,.29,0,.34.01Z"
        />
        <path
          className="cls-3"
          d="m96.85,7.91v.55c-.13.02-.28.04-.44.04-.33,0-.57-.08-.72-.24s-.23-.41-.23-.75v-.08c-.41.79-1.15,1.19-2.22,1.19-.66,0-1.17-.16-1.53-.47-.36-.31-.54-.74-.54-1.29,0-1.06.73-1.68,2.18-1.85l.95-.11c.42-.06.71-.16.88-.31.17-.15.25-.38.25-.69,0-.74-.53-1.12-1.58-1.12-.61,0-1.05.11-1.32.32-.27.21-.42.57-.45,1.08h-.65c.04-1.32.85-1.98,2.42-1.98.74,0,1.3.15,1.67.44s.56.71.56,1.25v3.58c0,.29.15.44.44.44h.32Zm-2.03-.4c.41-.34.62-.85.62-1.53v-.93c-.2.2-.54.32-1.02.38l-.95.12c-.56.07-.97.21-1.22.41-.25.2-.37.49-.37.87s.12.66.36.88.59.32,1.04.32c.61,0,1.12-.17,1.54-.52Z"
        />
        <path
          className="cls-3"
          d="m99.92,2.92h-1.42v4.25c0,.48.29.72.87.72.3,0,.49,0,.56-.01v.57c-.23.02-.44.04-.64.04-.97,0-1.45-.42-1.45-1.26V2.92h-1.04v-.59h1.04V.59h.67v1.73h1.42v.59Z"
        />
        <path
          className="cls-3"
          d="m100.82,1.4V.28h.71v1.12h-.71Zm.02,7.05V2.33h.68v6.13h-.68Z"
        />
        <path
          className="cls-3"
          d="m107.14,2.33h.72l-2.46,6.13h-.71l-2.46-6.13h.74l1.19,3.06c.3.74.6,1.52.89,2.36.1-.29.4-1.08.89-2.37l1.2-3.05Z"
        />
        <path
          className="cls-3"
          d="m108.8,7.7c-.53-.58-.8-1.35-.8-2.31s.27-1.74.8-2.33c.52-.59,1.21-.88,2.05-.88s1.54.29,2.08.88c.52.59.78,1.37.78,2.33s-.26,1.74-.78,2.31c-.54.59-1.23.89-2.08.89s-1.53-.3-2.05-.89Zm2.05.29c.66,0,1.18-.24,1.58-.71.39-.46.58-1.09.58-1.89s-.19-1.43-.58-1.9c-.4-.47-.92-.7-1.58-.7s-1.17.23-1.57.7c-.39.47-.58,1.1-.58,1.9s.19,1.43.58,1.89c.39.47.91.71,1.57.71Z"
        />
      </g>
    </svg>
  )
}

export const Administrativo = styled(Svg)
