'use client'
import { styled } from '../../config'

function Svg(props) {
  return (
    <svg
      width="119"
      height="34"
      viewBox="0 0 119 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M23.6929 26.4743H20.3982C20.3982 23.1518 19.7934 20.9483 18.5457 19.7285C16.8671 18.0915 13.9025 18.1645 10.4583 18.2444H9.81189V26.4743H6.51367V14.9879H8.16104C8.89784 14.9879 9.64854 14.9705 10.3784 14.9532C14.2014 14.8593 18.1739 14.762 20.8465 17.386C22.7615 19.2419 23.6929 22.2169 23.6929 26.4743Z"
        fill="black"
      />
      <path d="M28.492 15.0605H25.1973V26.474H28.492V15.0605Z" fill="black" />
      <path
        d="M48.3706 26.4748H45.0759C45.0759 23.9586 44.3321 22.0749 42.8029 20.709C40.5196 18.6655 36.7973 18.2102 34.4862 18.2067V26.4748H31.1914V15.1657L32.6685 14.9988C32.9847 14.9641 40.457 14.1786 45.0133 18.2519C47.2376 20.2503 48.3706 23.0168 48.3706 26.4748Z"
        fill="black"
      />
      <path
        d="M77.1128 19.8707V15.0051H75.462C74.7287 15.0051 73.978 14.9842 73.2481 14.9668C69.4251 14.873 65.4526 14.7757 62.78 17.3997C60.8615 19.2695 59.9336 22.2479 59.9336 26.5019H63.2283C63.2151 25.3944 63.3035 24.2881 63.4925 23.1967H73.8146V26.5019H77.1128V23.1689H80.4979V19.8707H77.1128ZM64.9487 19.8707C64.9904 19.8221 65.0356 19.7769 65.0808 19.7317C66.7594 18.0913 69.7309 18.1643 73.1682 18.2477L73.8146 18.265V19.8707H64.9487Z"
        fill="black"
      />
      <path
        d="M26.8457 13.8926C28.0031 13.8926 28.9414 12.9543 28.9414 11.7969C28.9414 10.6395 28.0031 9.70117 26.8457 9.70117C25.6883 9.70117 24.75 10.6395 24.75 11.7969C24.75 12.9543 25.6883 13.8926 26.8457 13.8926Z"
        fill="#0E3266"
      />
      <path
        d="M2.09918 26.8624C3.25853 26.8624 4.19836 25.9226 4.19836 24.7632C4.19836 23.6039 3.25853 22.6641 2.09918 22.6641C0.939836 22.6641 0 23.6039 0 24.7632C0 25.9226 0.939836 26.8624 2.09918 26.8624Z"
        fill="#E30613"
      />
      <path
        d="M47.9062 32.316V29.0177C50.4155 29.0177 52.2992 28.2775 53.6651 26.7552C55.8373 24.3224 56.164 20.4994 56.164 18.4141H47.9062V15.1367H57.7418C58.1468 15.1367 58.5376 15.286 58.8396 15.5559C59.1415 15.8258 59.3335 16.1974 59.3788 16.5999V16.5999C59.4135 16.9162 60.2025 24.3884 56.1292 28.9448C54.1308 31.183 51.3643 32.316 47.9062 32.316Z"
        fill="black"
      />
      <path
        d="M36.7111 5.0369C37.2263 5.68437 37.4882 6.49728 37.4479 7.32376C37.4876 8.15015 37.2258 8.96283 36.7111 9.61061C36.4641 9.89447 36.1568 10.1195 35.8116 10.2692C35.4665 10.419 35.0922 10.4896 34.7162 10.476C34.2607 10.4967 33.8092 10.3824 33.4185 10.1474C33.0277 9.91247 32.715 9.56733 32.5197 9.15533V10.3196H31.8906V2.00977H32.5544V5.4053C32.7572 5.01195 33.0688 4.68519 33.4521 4.46398C33.8353 4.24276 34.2742 4.13641 34.7162 4.1576C35.0933 4.14569 35.4683 4.2185 35.8135 4.37068C36.1588 4.52285 36.4655 4.75051 36.7111 5.0369V5.0369ZM34.6258 9.87822C34.921 9.89159 35.2153 9.83569 35.4851 9.71499C35.7548 9.59429 35.9926 9.41215 36.1794 9.18313C36.5866 8.64382 36.7898 7.97771 36.7528 7.3029C36.7893 6.63774 36.5831 5.98195 36.1724 5.45743C35.977 5.2424 35.7387 5.07059 35.473 4.95302C35.2072 4.83545 34.9199 4.77472 34.6293 4.77472C34.3387 4.77472 34.0513 4.83545 33.7856 4.95302C33.5199 5.07059 33.2816 5.2424 33.0862 5.45743C32.6757 5.98976 32.472 6.65278 32.5127 7.32376C32.4739 7.9945 32.6774 8.65688 33.0862 9.19008C33.2757 9.4127 33.5135 9.58914 33.7815 9.70598C34.0495 9.82282 34.3407 9.87697 34.6328 9.86432L34.6258 9.87822Z"
        fill="black"
      />
      <path
        d="M39.0926 9.58836C38.5493 8.95816 38.2692 8.14301 38.3107 7.31193C38.2737 6.48253 38.5532 5.67006 39.0926 5.03897C39.3444 4.75456 39.6561 4.52945 40.0052 4.37982C40.3543 4.23019 40.7323 4.15976 41.1119 4.17358C41.4948 4.16141 41.8759 4.23248 42.2287 4.38189C42.5815 4.5313 42.8977 4.75548 43.1555 5.03897C43.6869 5.67927 43.9607 6.49457 43.9235 7.32583C43.9645 8.15446 43.6901 8.96786 43.1555 9.60226C42.8993 9.88838 42.5836 10.115 42.2306 10.2663C41.8776 10.4176 41.4958 10.4899 41.1119 10.4781C40.7302 10.4899 40.3508 10.4161 40.0014 10.2621C39.652 10.1082 39.3415 9.87796 39.0926 9.58836ZM41.1119 9.86639C41.4068 9.8776 41.7004 9.82072 41.9698 9.70018C42.2392 9.57964 42.4772 9.39867 42.6654 9.1713C43.0706 8.64039 43.2727 7.9822 43.2354 7.3154C43.2716 6.64553 43.0697 5.98445 42.6654 5.44907C42.4699 5.23291 42.2313 5.06013 41.9649 4.94188C41.6985 4.82363 41.4103 4.76253 41.1188 4.76253C40.8274 4.76253 40.5392 4.82363 40.2728 4.94188C40.0064 5.06013 39.7678 5.23291 39.5723 5.44907C39.1654 5.98328 38.9621 6.64491 38.9988 7.3154C38.9606 7.98286 39.1642 8.64173 39.5723 9.1713C39.7569 9.39922 39.9923 9.58079 40.2596 9.70149C40.527 9.82218 40.8188 9.87866 41.1119 9.86639V9.86639Z"
        fill="black"
      />
      <path
        d="M52.9383 4.73406C53.3146 5.18148 53.5049 5.75616 53.4701 6.33972V10.3191H52.8271V6.42661C52.8271 5.32141 52.3336 4.76881 51.3466 4.76881C51.1076 4.76393 50.8707 4.81478 50.6547 4.91733C50.4388 5.01988 50.2496 5.17132 50.1024 5.35964C49.7494 5.84448 49.5739 6.43594 49.6054 7.03482V10.3365H48.9416V6.42661C48.9416 5.32141 48.4469 4.76881 47.4575 4.76881C47.2207 4.76502 46.9862 4.81644 46.7726 4.91899C46.5591 5.02154 46.3723 5.17241 46.2272 5.35964C45.8743 5.84448 45.6988 6.43594 45.7302 7.03482V10.3365H45.0664V4.29615H45.6955V5.40482C45.8365 5.0275 46.0926 4.70404 46.4275 4.48015C46.7624 4.25627 47.1592 4.14327 47.5618 4.15713C47.9847 4.12073 48.407 4.23261 48.7564 4.47358C49.1059 4.71455 49.3605 5.06959 49.4768 5.47781C49.6263 5.08373 49.8938 4.7454 50.2427 4.50903C50.5917 4.27266 51.0051 4.14976 51.4265 4.15713C51.7051 4.13991 51.9841 4.18248 52.2449 4.28199C52.5056 4.38149 52.7421 4.53563 52.9383 4.73406V4.73406Z"
        fill="black"
      />
      <path
        d="M59.6916 5.0369C60.2058 5.68471 60.4665 6.49767 60.4249 7.32376C60.4659 8.14975 60.2053 8.9625 59.6916 9.61061C59.4426 9.89202 59.1341 10.1144 58.7884 10.2617C58.4427 10.409 58.0686 10.4775 57.6932 10.4621C57.2381 10.483 56.787 10.3688 56.3967 10.1337C56.0065 9.89871 55.6945 9.55344 55.5002 9.14142V10.3196H54.8711V2.00977H55.5349V5.4053C55.7364 5.01166 56.0472 4.68455 56.4301 4.46323C56.8129 4.24191 57.2515 4.13579 57.6932 4.1576C58.0708 4.14567 58.4463 4.21845 58.7921 4.37061C59.1379 4.52276 59.4452 4.75043 59.6916 5.0369V5.0369ZM57.6063 9.87822C57.9011 9.89214 58.195 9.83647 58.4643 9.71571C58.7336 9.59496 58.9707 9.41251 59.1563 9.18313C59.5636 8.64381 59.7668 7.97771 59.7298 7.3029C59.7673 6.63818 59.5623 5.98243 59.1529 5.45743C58.9651 5.23141 58.7281 5.0513 58.46 4.93083C58.192 4.81036 57.9 4.75273 57.6063 4.76234C57.3135 4.75223 57.0223 4.80967 56.7554 4.93021C56.4884 5.05075 56.2527 5.23115 56.0667 5.45743C55.6562 5.98976 55.4525 6.65278 55.4932 7.32376C55.4543 7.9945 55.6579 8.65687 56.0667 9.19008C56.256 9.4129 56.4938 9.58948 56.7618 9.70634C57.0299 9.8232 57.3211 9.87723 57.6132 9.86432L57.6063 9.87822Z"
        fill="black"
      />
      <path
        d="M66.6945 7.53442H61.9644C61.9524 8.1596 62.1616 8.76892 62.5553 9.25478C62.7447 9.45699 62.9759 9.61552 63.2327 9.71935C63.4896 9.82319 63.766 9.86983 64.0428 9.85604C64.4733 9.87708 64.8968 9.74047 65.234 9.47179C65.5711 9.20311 65.7988 8.82077 65.8743 8.39634H66.5694C66.4559 8.98512 66.1444 9.51737 65.6866 9.90469C65.2139 10.2841 64.6208 10.4814 64.0149 10.4608C63.6386 10.4772 63.2634 10.4078 62.9178 10.2579C62.5722 10.1079 62.2652 9.8814 62.02 9.59538C61.5054 8.94759 61.2435 8.13491 61.2832 7.30852C61.2509 6.48788 61.5135 5.6828 62.0235 5.03904C62.264 4.75283 62.5671 4.52587 62.9095 4.37579C63.2518 4.22571 63.6242 4.15654 63.9976 4.17365C64.373 4.15273 64.7481 4.22012 65.0927 4.37042C65.4373 4.52071 65.742 4.74971 65.9821 5.03904C66.4734 5.68582 66.7252 6.48297 66.6945 7.29462V7.53442ZM62.597 5.33793C62.212 5.78322 61.9934 6.34822 61.9783 6.93664H65.9821C65.973 6.35907 65.7684 5.80163 65.4017 5.3553C65.2274 5.16113 65.0125 5.00781 64.7722 4.90628C64.5319 4.80475 64.2721 4.75752 64.0115 4.76795C63.7486 4.75622 63.4864 4.80103 63.2423 4.89936C62.9983 4.99769 62.7782 5.14725 62.597 5.33793V5.33793Z"
        fill="black"
      />
      <path
        d="M70.857 4.22837V4.86091H70.5755C70.2937 4.84349 70.0113 4.88418 69.7458 4.98043C69.4804 5.07668 69.2376 5.22646 69.0324 5.42046C68.8419 5.62507 68.6946 5.86588 68.599 6.12858C68.5035 6.39128 68.4618 6.67051 68.4763 6.94966V10.3209H67.8125V4.29788H68.4416V5.55947C68.6039 5.14498 68.8928 4.79223 69.2672 4.55139C69.6416 4.31054 70.0823 4.19386 70.5268 4.21794C70.6902 4.21794 70.8014 4.22142 70.857 4.22837Z"
        fill="black"
      />
      <path
        d="M71.8485 9.58824C71.3051 8.95804 71.0251 8.1429 71.0665 7.31181C71.0296 6.48242 71.3091 5.66995 71.8485 5.03886C72.1006 4.75483 72.4123 4.52998 72.7614 4.38038C73.1104 4.23079 73.4882 4.16013 73.8677 4.17347C74.2507 4.16106 74.6318 4.23203 74.9847 4.38145C75.3375 4.53087 75.6537 4.75518 75.9113 5.03886C76.4455 5.67782 76.7208 6.49376 76.6829 7.32571C76.7243 8.155 76.4484 8.96896 75.9113 9.60214C75.6553 9.88845 75.3396 10.1152 74.9866 10.2665C74.6336 10.4178 74.2517 10.49 73.8677 10.478C73.4862 10.4893 73.1069 10.4153 72.7576 10.2613C72.4082 10.1074 72.0977 9.87746 71.8485 9.58824V9.58824ZM73.8677 9.86628C74.1627 9.87749 74.4562 9.82061 74.7256 9.70007C74.9951 9.57953 75.2331 9.39856 75.4213 9.17119C75.8293 8.64162 76.0329 7.98275 75.9947 7.31529C76.0313 6.6432 75.8267 5.98014 75.4178 5.44549C75.2223 5.22932 74.9837 5.05655 74.7173 4.93829C74.4509 4.82004 74.1627 4.75895 73.8712 4.75895C73.5798 4.75895 73.2916 4.82004 73.0252 4.93829C72.7588 5.05655 72.5201 5.22932 72.3246 5.44549C71.9178 5.97969 71.7145 6.64133 71.7512 7.31181C71.713 7.97927 71.9166 8.63814 72.3246 9.16771C72.5098 9.39613 72.7457 9.57821 73.0136 9.69947C73.2815 9.82074 73.5739 9.87786 73.8677 9.86628Z"
        fill="black"
      />
      <path
        d="M102.332 33.8562C111.538 33.8562 119.001 26.3935 119.001 17.1879C119.001 7.9822 111.538 0.519531 102.332 0.519531C93.1267 0.519531 85.6641 7.9822 85.6641 17.1879C85.6641 26.3935 93.1267 33.8562 102.332 33.8562Z"
        fill="#0E3266"
      />
      <path
        d="M102.332 33.8562C111.538 33.8562 119.001 26.3935 119.001 17.1879C119.001 7.9822 111.538 0.519531 102.332 0.519531C93.1267 0.519531 85.6641 7.9822 85.6641 17.1879C85.6641 26.3935 93.1267 33.8562 102.332 33.8562Z"
        fill="#0E3266"
      />
      <path
        d="M114.121 23.5714H109.999C109.999 20.4435 109.071 18.0767 107.17 16.3703C104.139 13.6524 99.3602 13.2423 96.7432 13.2423V23.5714H92.6387V9.42975L94.4668 9.2247C94.863 9.17952 104.198 8.18206 109.905 13.2875C112.703 15.7864 114.121 19.2479 114.121 23.5714Z"
        fill="#E30613"
      />
    </svg>
  )
}

export const Bombero = styled(Svg, {})
