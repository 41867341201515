'use client'
import { styled } from '../config'

export const Text = styled('p', {
  margin: 0,
  padding: 0,
  fontFamily: '$inter',
  fontSize: '$body',
  variants: {
    grey: {
      true: {
        color: '#737980',
      },
    },
    variant: {
      Standard: {
        color: '$blue100',
      },
      Study: {
        color: '$purple100',
      },
      Simulacrum: {
        color: '$green100',
      },
    },
  },
})
