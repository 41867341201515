'use client'
import { styled } from '../../config'

function Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 803.1 228.35"
      {...props}>
      <defs>
        <style>
          {'.cls-1{fill:#fcc004;}.cls-2{fill:#fff;}.cls-3{fill:#e30613;}'}
        </style>
      </defs>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <rect className="cls-1" width="803.1" height="136.44" />
          <path
            className="cls-2"
            d="M118.88,41.24q5.69,5.91,5.68,15.8V99.73H114.8V58q0-13.9-13.18-13.9a14.33,14.33,0,0,0-11.53,5.55q-4.57,5.55-4.57,15.67v34.4H75.76V58q0-13.9-13.18-13.9a14.37,14.37,0,0,0-11.46,5.55q-4.63,5.55-4.64,15.67v34.4H36.72V36.79H46v9.27q6.23-10.74,18.18-10.74,14.14,0,19.4,11.84Q90.16,35.33,103,35.32,113.22,35.32,118.88,41.24Z"
          />
          <path
            className="cls-2"
            d="M194.1,71.68H146.28Q147,81.93,152,87.11t13.66,5.18q13.07,0,16.72-11.71h10.12A28,28,0,0,1,183,95.65q-7,5.55-17.57,5.55-13.05,0-21.1-8.91t-8.18-24q0-14.88,8-23.91t21-9q13.53,0,21.22,9.39t7.69,23.55ZM152.62,49.05q-4.87,4.81-6.1,13.84h37.09A21.84,21.84,0,0,0,178,49.41a16.82,16.82,0,0,0-12.81-5.18A17.09,17.09,0,0,0,152.62,49.05Z"
          />
          <path
            className="cls-2"
            d="M287.85,41.24q5.68,5.91,5.68,15.8V99.73h-9.76V58q0-13.9-13.18-13.9a14.33,14.33,0,0,0-11.53,5.55q-4.58,5.55-4.57,15.67v34.4h-9.76V58q0-13.9-13.18-13.9a14.37,14.37,0,0,0-11.46,5.55q-4.64,5.55-4.64,15.67v34.4h-9.76V36.79H215v9.27q6.22-10.74,18.18-10.74,14.15,0,19.4,11.84,6.59-11.83,19.39-11.84Q282.17,35.32,287.85,41.24Z"
          />
          <path
            className="cls-2"
            d="M335.62,101.2q-13.31,0-22-9t-8.54-23.91q0-15,8.54-23.91t22-9q13.29,0,22,9t8.54,23.91q0,15-8.54,23.91T335.62,101.2Zm0-8.78q9.27,0,14.76-6.47t5.49-17.69q0-11.46-5.49-17.81t-14.76-6.34q-9.27,0-14.76,6.34t-5.49,17.81q0,11.34,5.49,17.69T335.62,92.42Z"
          />
          <path
            className="cls-2"
            d="M410.53,35.93v9.4h-2.44q-9.76,0-15.19,4.81t-5.43,13.73V99.73h-9.76V36.79H387v11.1q6.34-12.09,20.25-12.08A28.94,28.94,0,0,1,410.53,35.93Z"
          />
          <path
            className="cls-2"
            d="M419.31,27.27v-12h10.37v12Zm.37,72.46V36.79h9.76V99.73Z"
          />
          <path
            className="cls-2"
            d="M499.09,91.8v7.93a35.34,35.34,0,0,1-5.36.37q-9.89,0-10.62-9-6.58,10.13-20.49,10.13-10.37,0-16.29-4.82t-5.91-13.6q0-17.09,23.3-19.52l9-1q9.87-1.1,9.88-8.66,0-5.25-3.24-7.56t-10.91-2.32q-8.18,0-11.78,2.74t-4.08,9.7h-9.76q1.22-20.85,25.62-20.86,23.65,0,23.66,18.3V88.75c0,2,1.14,3,3.42,3Zm-22.32-3.66q5.85-4.64,5.86-13.29V67.41q-2.69,2.07-8.3,2.68l-9.15,1.1c-5.28.65-9.05,1.87-11.28,3.66a9.26,9.26,0,0,0-3.36,7.68,9.56,9.56,0,0,0,3.17,7.63q3.18,2.75,9.4,2.74A21,21,0,0,0,476.77,88.14Z"
          />
          <path
            className="cls-2"
            d="M576.2,41.06q5.85,5.73,5.85,16.1V99.73h-9.76V58.13q0-14-14.39-14a16.77,16.77,0,0,0-12.08,5.12q-5.24,5.12-5.24,14.88V99.73h-9.76V36.79h9.27v9.39q7.2-10.86,19.76-10.86Q570.34,35.32,576.2,41.06Z"
          />
          <path
            className="cls-2"
            d="M596.33,27.27v-12H606.7v12Zm.36,72.46V36.79h9.76V99.73Z"
          />
          <path
            className="cls-2"
            d="M666.48,41.06q5.85,5.73,5.85,16.1V99.73h-9.76V58.13q0-14-14.39-14a16.77,16.77,0,0,0-12.08,5.12q-5.25,5.12-5.25,14.88V99.73h-9.76V36.79h9.28v9.39q7.2-10.86,19.76-10.86Q660.62,35.32,666.48,41.06Z"
          />
          <path
            className="cls-2"
            d="M677,123.89v-8.42h4q6,0,6-5.37V36.79h9.76V111q0,13-14.51,13.05C679.61,124,677.87,124,677,123.89Zm9.64-96.62v-12H697v12Z"
          />
          <path
            className="cls-2"
            d="M766.39,91.8v7.93a35.34,35.34,0,0,1-5.36.37q-9.88,0-10.62-9-6.58,10.13-20.49,10.13-10.38,0-16.29-4.82t-5.92-13.6q0-17.09,23.3-19.52l9-1q9.88-1.1,9.88-8.66c0-3.5-1.07-6-3.23-7.56s-5.79-2.32-10.92-2.32q-8.17,0-11.77,2.74t-4.09,9.7h-9.76q1.23-20.85,25.62-20.86,23.67,0,23.67,18.3V88.75c0,2,1.14,3,3.41,3Zm-22.32-3.66q5.85-4.64,5.85-13.29V67.41q-2.68,2.07-8.29,2.68l-9.15,1.1q-7.93,1-11.29,3.66a9.28,9.28,0,0,0-3.35,7.68A9.56,9.56,0,0,0,721,90.16q3.18,2.75,9.4,2.74A21,21,0,0,0,744.07,88.14Z"
          />
          <path d="M620.1,209.86h-10c0-10.77-2-17.93-6.05-21.9-5.49-5.36-15.09-5.13-26.22-4.85l-2.38,0v26.7h-10V173.22h5c2.37,0,4.79-.05,7.13-.11,12.27-.3,24.95-.61,33.46,7.69C617.14,186.75,620.1,196.26,620.1,209.86Z" />
          <rect x="625.54" y="173.16" width="10" height="36.7" />
          <path d="M699.47,209.86h-10c0-8.16-2.41-14.29-7.38-18.75-8-7.15-20.56-8.13-27.27-8.11v26.86h-10v-36.1l4.44-.5c1-.12,24.92-2.63,39.47,10.37C695.86,190,699.47,198.83,699.47,209.86Z" />
          <path d="M791.9,188.93v-15.7h-5c-2.36,0-4.79-.06-7.13-.12-12.26-.3-24.95-.61-33.45,7.69-6.1,6-9.07,15.46-9.07,29.06h10a58.38,58.38,0,0,1,.9-10.93H781.9v10.93h10V198.93h11.19v-10Zm-10,0H752.41a11.76,11.76,0,0,1,.89-1c5.49-5.36,15.1-5.12,26.23-4.85l2.37,0Z" />
          <circle className="cls-3" cx="630.54" cy="162.66" r="6.75" />
          <circle className="cls-3" cx="550.96" cy="204.36" r="6.75" />
          <path d="M698.27,228.35v-10c8.16,0,14.29-2.41,18.75-7.38,7.15-8,8.14-20.61,8.11-27.27H698.27v-10h31.64a5,5,0,0,1,5,4.44h0c.12,1,2.63,24.92-10.37,39.47C718.12,224.74,709.3,228.35,698.27,228.35Z" />
          <path d="M490.05,178.25q4.33,5.26,4.34,13.94t-4.34,13.86a14.33,14.33,0,0,1-11.74,5.34,14.57,14.57,0,0,1-8.82-2.7,15.81,15.81,0,0,1-5.47-7.61v9.46H462.1V159.9h2v23.18a15.63,15.63,0,0,1,5.47-7.43,15.7,15.7,0,0,1,20.49,2.6Zm-11.88,31.36a12.54,12.54,0,0,0,10.38-4.84q3.84-4.76,3.84-12.65t-3.84-12.59a13.68,13.68,0,0,0-20.76,0Q464,184.36,464,192.19t3.84,12.58A12.54,12.54,0,0,0,478.17,209.61Z" />
          <path d="M525.54,173.84h2.13L512,215.16a19,19,0,0,1-4.12,7.18,8.13,8.13,0,0,1-5.9,2.28c-1.33,0-2.21,0-2.64-.07v-1.71c.43,0,1.31.07,2.64.07a6.25,6.25,0,0,0,4.73-2,19.38,19.38,0,0,0,3.52-6.19c.19-.66.61-1.8,1.28-3.41l-15.22-37.48h2.13l8.39,20.91q5.55,14.15,5.62,14.36.86-2.49,5.26-14.29Z" />
        </g>
      </g>
    </svg>
  )
}

export const Memoria = styled(Svg)
