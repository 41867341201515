import { useEditorProvider } from '../editor-provider'
import { CustomButton } from './custom-button'
import { CustomImage } from './custom-image'
import { CustomVideo } from './custom-video'
import { Link } from './link'

export const RenderElement = ({ attributes, children, element }) => {
  const { onClickAction } = useEditorProvider()
  const style = { textAlign: element.align }

  switch (element.type) {
    case 'link':
      return (
        <Link
          attributes={attributes}
          element={{ ...element, openInNewTab: true }}
          style={style}
          onClickAction={async () => {
            if (onClickAction) await onClickAction()
          }}>
          {children}
        </Link>
      )
    case 'button-custom':
      return (
        <CustomButton
          attributes={attributes}
          children={children}
          element={element}
          style={style}
          onClickAction={async () => {
            if (onClickAction) await onClickAction()
          }}
        />
      )
    case 'image-custom':
      return (
        <CustomImage
          attributes={attributes}
          children={children}
          element={element}
          style={style}
        />
      )
    case 'video-custom':
      return (
        <CustomVideo
          attributes={attributes}
          children={children}
          element={element}
          style={style}
        />
      )
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <span style={style} {...attributes} className={'text-[20px] font-bold'}>
          {children}
        </span>
      )
    case 'heading-two':
      return (
        <span
          style={style}
          {...attributes}
          className={'text-[18px] font-semibold'}>
          {children}
        </span>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'normal2lines':
      return (
        <span
          className={'text-ellipsis overflow-hidden leading-6 h-12'}
          {...attributes}
          style={{
            ...style,
          }}>
          {children}
        </span>
      )
    case 'p':
      return (
        <div className="leading-normal" style={style} {...attributes}>
          {children}
        </div>
      )
    case 'div':
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      )
    default:
      return (
        <span style={style} {...attributes}>
          {children}
        </span>
      )
  }
}
