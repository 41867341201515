'use client'
import isHotkey from 'is-hotkey'
import {
  KeyboardEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
} from 'react'
import { Editable, ReactEditor, useSlate } from 'slate-react'
import { RenderElement } from './components/render-element'
import { RenderLeaf } from './components/render-leaf'
import { HOTKEYS, toggleMark } from './funcitons'

type EditorInputProps = {
  placeholder?: string
  readOnly?: boolean
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>
}

export function EditorInput({
  placeholder,
  readOnly = false,
  onKeyDown,
}: EditorInputProps) {
  const editor = useSlate()

  const renderElement = useCallback((props) => <RenderElement {...props} />, [])

  const renderLeaf = useCallback((props) => <RenderLeaf {...props} />, [])

  const defaultKeyBidings = (event: KeyboardEvent<HTMLDivElement>) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault()
        const mark = HOTKEYS[hotkey]
        toggleMark(editor, mark)
        return true
      }
    }
    return false
  }

  const onKeyDownHandler = (event: KeyboardEvent<HTMLDivElement>) => {
    if (defaultKeyBidings(event)) {
      return
    }
    onKeyDown && onKeyDown(event)
  }

  useEffect(() => {
    if (!readOnly && editor) {
      ReactEditor.focus(editor as any)
    }
  }, [readOnly, editor])

  return (
    <Editable
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      placeholder={placeholder}
      spellCheck
      autoFocus
      onKeyDown={onKeyDownHandler}
      readOnly={readOnly}
    />
  )
}
