'use client'
import { Root, Thumb } from '@radix-ui/react-switch'

export function Switch({ checked, defaultChecked, onCheckedChange }) {
  return (
    <Root
      className="h-[20px] w-[37px] min-w-[37px] border border-gray-400 rounded-full bg-[#E6E6E6] shadow-[0_2px_10px_#F1F1F1] data-[state=checked]:bg-admin-blue"
      checked={checked}
      defaultChecked={defaultChecked}
      onCheckedChange={onCheckedChange}>
      <Thumb className="block z-0 h-[20px] w-[20px] translate-x-[-1px] translate-y-[-1px] border border-gray-400 rounded-full bg-white transition-transform duration-100 data-[state=checked]:translate-x-[16px]" />
    </Root>
  )
}
