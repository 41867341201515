import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-dialog'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import './dialog.css'

const urlFormSchema = z.object({
  link: z.string().url(),
})

export type LinkForm = z.infer<typeof urlFormSchema>

export function LinkDialog({
  children,
  title,
  value,
  onConfirmValue,
  onRemoveButton,
  open,
  onOpenChange,
}: {
  children: React.ReactNode
  title: string
  value?: string
  onConfirmValue?: (value: LinkForm) => void
  onRemoveButton?: () => void
  open: boolean
  onOpenChange: (open: boolean) => void
}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<LinkForm>({
    resolver: zodResolver(urlFormSchema),
  })

  const onSubmit = (data: LinkForm) => {
    onConfirmValue?.(data)
    onOpenChange(false)
  }
  useEffect(() => {
    setValue('link', value as any)
  }, [value, setValue])

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="overlay">
          <Dialog.Content className="container">
            <form onSubmit={handleSubmit(onSubmit)} className="card">
              <p className={'title'}>{title}</p>

              <fieldset className="w-full">
                <p className={'legend'}>Link</p>
                <input
                  type="url"
                  placeholder="https://www.google.com"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  {...register('link')}
                />
              </fieldset>

              <div className="bottom-buttons">
                <Dialog.Close asChild>
                  <motion.button className="p-2 disabled:bg-gray-400 px-5 justify-center items-center flex border border-gray-400 text-black text-sm rounded">
                    {'Cancelar'}
                  </motion.button>
                </Dialog.Close>
                <button onClick={onRemoveButton} className={'remove-button'}>
                  {'Quitar elemento'}
                </button>
                <button
                  disabled={!isValid}
                  type="submit"
                  className={'confirm-button' + (isValid ? '' : ' disabled')}>
                  {'Guardar'}
                </button>
              </div>
            </form>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
