import { useFocused, useSelected } from 'slate-react'

export const CustomImage = ({ attributes, children, element, style }) => {
  const selected = useSelected()
  const focused = useFocused()

  return (
    <span
      {...attributes}
      style={{
        boxShadow: selected && focused ? '0 0 0 1px #B4D5FF' : 'none',
        ...style,
      }}>
      <img
        contentEditable={false}
        src={element.url}
        style={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: '20em',
        }}
      />
      {children}
    </span>
  )
}
