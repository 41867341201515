import { BaseEditor, Editor, Element, Path, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { createParagraphNode } from './paragraph'

export type NodeProps = {
  type: string
  text: string
  [key: string]: string | boolean
}

export function createNode({ type, text, ...props }: NodeProps) {
  return {
    type,
    children: [{ text }],
    ...props,
  }
}

export function addNode(editor: BaseEditor, props: NodeProps) {
  if (!props || !props.type) return

  const { selection } = editor
  const node = createNode(props)

  ReactEditor.focus(editor as ReactEditor)

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    )

    // Remove the Link node if we're inserting a new link node inside of another
    // link.
    if ((parentNode as any).type === props.type) {
      removeElement(editor, props.type)
    }

    if (editor.isVoid(parentNode)) {
      // Insert the new link after the void node
      Transforms.insertNodes(
        editor,
        createParagraphNode({ text: props.text }),
        {
          at: Path.next(parentPath),
          select: true,
        }
      )
    } else if (Range.isCollapsed(selection)) {
      // Insert the new node in our last known location
      Transforms.insertNodes(editor, node, { select: true })
    } else {
      // Wrap the currently selected range of text into a Link
      Transforms.wrapNodes(editor, node, { split: true })
      Transforms.collapse(editor, { edge: 'end' })
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is false
    Transforms.insertNodes(editor, createParagraphNode({ text: props.text }))
  }
}

export function removeElement(editor: BaseEditor, type: string) {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && (n as any).type === type,
  })
}
