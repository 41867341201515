'use client'
import { slateToHtml } from '@slate-serializers/html'
import { Editor, Transforms } from 'slate'
import { useSlate } from 'slate-react'

export const useEditor = () => {
  const editor = useSlate()

  return {
    getValue() {
      return slateToHtml(editor.children)
    },
    getRawValue() {
      return editor.children
    },
    requestFocus() {
      if (editor) {
        Transforms.select(editor, { offset: 0, path: [0, 0] })
      }
    },
    clear() {
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      })
    },
  }
}
