'use client'
import { motion } from 'framer-motion'
import { MouseEventHandler } from 'react'

export type SendButtonProps = {
  children: React.ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export function SendButton({ children, onClick, disabled }: SendButtonProps) {
  return (
    <div className="px-4 h-[29px] bg-blue-secondary rounded-[8px] text-[10px] leading-6 text-white flex justify-center items-center cursor-pointer">
      <motion.button
        whileTap={{ scale: 0.95 }}
        disabled={disabled}
        onClick={onClick}
        className="send-button">
        {children}
      </motion.button>
    </div>
  )
}
