'use client'

import { keyframes, styled } from '../config'

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3,auto)',
  columnGap: '4px',
  '@bp1': {
    columnGap: '2px',
  },
})

export function Spinner({ color }) {
  return (
    <Container>
      <Bounce css={{ background: color }} />
      <Bounce2 css={{ background: color }} />
      <Bounce1 css={{ background: color }} />
    </Container>
  )
}

const bounce = keyframes({
  '0%, 40%, 100%': { opacity: 0 },
  '40%': { opacity: 1 },
})

const Bounce = styled('div', {
  width: '10px',
  height: '10px',

  borderRadius: '100%',
  display: 'inline-block',
  animation: `${bounce}  1.4s infinite ease-in-out both`,
  '@bp1': {
    width: '6px',
    height: '6px',
  },
})

const Bounce1 = styled('div', {
  width: '10px',
  height: '10px',

  borderRadius: '100%',
  display: 'inline-block',
  animation: `${bounce} 1.4s infinite ease-in-out both`,
  animationDelay: '-0.32s',
  '@bp1': {
    width: '6px',
    height: '6px',
  },
})

const Bounce2 = styled('div', {
  width: '10px',
  height: '10px',

  borderRadius: '100%',
  display: 'inline-block',
  animation: `${bounce}  1.4s infinite ease-in-out both`,
  animationDelay: '-0.16s',
  '@bp1': {
    width: '6px',
    height: '6px',
  },
})
